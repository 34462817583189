import React from 'react';
import Video from '../../components/video';
import { Helmet } from 'react-helmet';

const BlackLikeMeow = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Black Like Meow" />
        <meta name="twitter:image" content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png" />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="video">
        <div className="videoTitle">
          <p>
            BLACK LIKE MEOW
          </p>
        </div>
        <Video
          videoSrcURL="https://player.vimeo.com/video/430291373"
          videoTitle="Black Like Meow"
          width="350"
          height="190"
        />
      </div>
      <div className="articleCopyright">
        <p>&copy; Shoya, Inc. 2021</p>
      </div>
    </div>
  );
};
export default BlackLikeMeow;

